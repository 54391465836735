import { PageDto, PaginatedResponseDto, PolicyDto, SiteDto } from '../models';
import { BrandingDto } from '../models/dto/configuration/branding';
import { adminApi } from './';

const sitesApi = adminApi.enhanceEndpoints({ addTagTypes: ['Site', 'SitePolicy'] }).injectEndpoints({
  endpoints: build => ({
    getSite: build.query<SiteDto, void>({
      query: () =>
        `/api/sites?filters[domain][$eq]=${window.location.host}&populate=footer.links&populate=logo&populate=logoDark&populate=favicon&populate=faviconDark`,
      providesTags: ['Site'],
      transformResponse: (response: PaginatedResponseDto<SiteDto>) => response?.data?.[0],
    }),
    getSitePage: build.query<PageDto, { pageName: string }>({
      query: () =>
        `api/sites?filters[domain][$eq]=${window.location.host}&populate=pages.teasers.items.image&populate=pages.partners.items.image&populate=pages.hero.cta&populate=pages.hero.image&populate=pages.hero.mobileImage&populate=pages.newsletterSubscription&populate=pages.cardLists.items.image&populate=pages.hero.ctaList`,
      providesTags: ['Site'],
      transformResponse: (response: PaginatedResponseDto<SiteDto>, _, { pageName }) =>
        response.data?.[0]?.pages?.find(p => p.__component === `pages.${pageName}`) || ({} as PageDto),
    }),
    getSitePolicy: build.query<PolicyDto, void>({
      query: () => `/api/sites?filters[domain][$eq]=${window.location.host}&populate=policy`,
      providesTags: ['SitePolicy'],
      transformResponse: (response: PaginatedResponseDto<SiteDto>) => response?.data?.[0]?.policy,
    }),
    getSiteTheme: build.query<BrandingDto, void>({
      query: () =>
        `/api/sites?filters[domain][$eq]=${window.location.host}&populate=theme.palette.primary&populate=theme.palette.secondary&populate=theme.palette.info&populate=theme.palette.warning&populate=theme.palette.error&populate=theme.palette.background`,
      providesTags: ['Site'],
      transformResponse: (response: PaginatedResponseDto<SiteDto>) => response?.data?.[0]?.theme,
    }),
  }),
});

export const {
  useGetSiteQuery: useSite,
  useGetSitePageQuery: useSitePage,
  useGetSitePolicyQuery: useSitePolicy,
  useGetSiteThemeQuery: useSiteTheme,
} = sitesApi;
