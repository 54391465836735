import { Box, EditIcon, GridActionsCellItem, GridRenderEditCellParams, Stack, Typography } from '@sgde/core';
import { useState } from 'react';
import { CronjobModal } from './CronjobModal';
import { useStyles } from './RecursiveEditCell.style.ts';

export const RecursiveEditCell = (props: GridRenderEditCellParams) => {
  const { classes } = useStyles();

  const [isWebPageModalOpen, setIsWebPageModalOpen] = useState(false);

  return (
    <Stack className={classes.stack}>
      <Box className={classes.box}>
        <Typography variant="body2">{props.row.isRecursive ? 'Da' : 'Nu'}</Typography>
      </Box>
      {props.showEditIcon ? (
        <Box className={classes.box}>
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Da"
            color="inherit"
            onClick={() => {
              setIsWebPageModalOpen(true);
            }}
          />
        </Box>
      ) : null}

      <CronjobModal isOpen={isWebPageModalOpen} closeModal={() => setIsWebPageModalOpen(false)} props={props} />
    </Stack>
  );
};
