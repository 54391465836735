import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  GridRenderEditCellParams,
  InputLabel,
  MenuItem,
  MuiXDatePicker,
  Select,
  Switch,
  TextField,
  useGridApiContext,
} from '@sgde/core';
import { useEffect, useState } from 'react';
import { CronJobDto, CronJobType, ResourceOccurrenceType } from '../../../models/dto/chat/cronJob.ts';
import { useStyles } from './CronjobModal.styles.ts';

interface IProps {
  isOpen: boolean;
  closeModal: () => void;
  props: GridRenderEditCellParams;
}

export const CronjobModal = ({ isOpen, closeModal, props }: IProps) => {
  const { classes } = useStyles();

  const cronJobObject: CronJobDto = {
    id: undefined,
    startDate: new Date().toISOString(),
    occurrence: 1,
    occurrenceType: ResourceOccurrenceType.Daily,
    type: CronJobType.WebPage,
  };

  const [isRecursive, setIsRecursive] = useState(false);
  const [cronJobFormData, setCronJobFormData] = useState(cronJobObject);
  const [minEndDate, setMinEndDate] = useState(new Date(cronJobObject.startDate));
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const apiRef = useGridApiContext();

  useEffect(() => {
    setCronJobFormData({ ...cronJobFormData, ...props.row.cronJob });
    setIsRecursive(props.row.isRecursive);
  }, [props]);

  useEffect(() => {
    const newMinEndDate = new Date(cronJobFormData.startDate);
    newMinEndDate.setDate(newMinEndDate.getDate() + 1);
    setMinEndDate(newMinEndDate);
  }, [cronJobFormData.startDate]);

  const handleValueChange = async () => {
    await apiRef.current.setEditCellValue({
      id: props.row.id,
      field: 'isRecursive',
      value: isRecursive,
    });
    await apiRef.current.setEditCellValue({
      id: props.row.id,
      field: 'cronJob',
      value: { ...cronJobFormData },
    });
  };

  const onEndDateError = (errorMesage?: string | null) => {
    const errorToDisplay = errorMesage === 'minDate' ? 'Data trebuie sa fie mai mare decat data de inceput' : '';
    setErrors({ ...errors, endDate: errorToDisplay });
  };

  const handleCloseModal = () => {
    closeModal();
  };

  const handleSave = async () => {
    handleValueChange();
    closeModal();
  };

  const isSaveButtonDisabled = Object.values(errors).some(value => !!value);

  return (
    <Dialog open={isOpen} onClose={handleCloseModal} className={classes.dialog}>
      <DialogTitle>Pagina Web</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <FormControlLabel
              value={isRecursive}
              control={
                <Switch checked={isRecursive} color="primary" onChange={() => setIsRecursive(!isRecursive)} autoFocus />
              }
              label="Recursiv"
              labelPlacement="end"
            />
          </Grid>

          <Grid item xs={6}>
            <FormControl>
              <MuiXDatePicker
                disabled={!isRecursive}
                label="Data inceput"
                value={cronJobFormData.startDate}
                minDate={new Date()}
                onDateChange={date => setCronJobFormData({ ...cronJobFormData, startDate: date })}
              />
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl>
              <MuiXDatePicker
                disabled={!isRecursive}
                label="Data sfarsit"
                value={cronJobFormData.endDate || null}
                minDate={minEndDate}
                onDateChange={date => setCronJobFormData({ ...cronJobFormData, endDate: date })}
                onDateError={onEndDateError}
                errorMessage={errors.endDate}
              />
            </FormControl>
          </Grid>

          <Grid item container spacing={6}>
            <Grid item xs={4}>
              <TextField
                required
                margin="dense"
                id="occurrence"
                name="occurrence"
                label="Incidenta"
                type="number"
                fullWidth
                variant="standard"
                value={cronJobFormData.occurrence}
                onChange={e => setCronJobFormData({ ...cronJobFormData, occurrence: parseInt(e.target.value) })}
                inputProps={{ min: 1, max: 100 }}
                disabled={!isRecursive}
              />
            </Grid>
            <Grid item xs={8}>
              <FormControl fullWidth margin="dense" variant="standard">
                <InputLabel id="occurrenceType-label">Tipul de aparitie</InputLabel>
                <Select
                  labelId="occurrenceType-label"
                  id="occurrenceType"
                  disabled={!isRecursive}
                  name="occurrenceType"
                  value={cronJobFormData.occurrenceType}
                  onChange={e =>
                    setCronJobFormData({ ...cronJobFormData, occurrenceType: e.target.value as ResourceOccurrenceType })
                  }
                >
                  <MenuItem value={ResourceOccurrenceType.Daily}>Zi</MenuItem>
                  <MenuItem value={ResourceOccurrenceType.Weekly}>Saptamana</MenuItem>
                  <MenuItem value={ResourceOccurrenceType.Monthly}>Luna</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={handleCloseModal}>
          Inchide
        </Button>
        <Button variant="outlined" color="secondary" type="submit" onClick={handleSave} disabled={isSaveButtonDisabled}>
          Salveaza
        </Button>
      </DialogActions>
    </Dialog>
  );
};
