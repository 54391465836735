import { Checkbox, TableCell, TableHead, TableRow, TableSortLabel, UserDto } from '@sgde/core';
import { ChangeEvent } from 'react';

interface Props {
  numSelected: number;
  onSort: (property: keyof UserDto) => void;
  onSelectAll: (event: ChangeEvent<HTMLInputElement>) => void;
  order: 'asc' | 'desc';
  orderBy: string;
  rowCount: number;
}

export const UsersTableHead = ({ onSelectAll, order, orderBy, numSelected, rowCount, onSort }: Props) => (
  <TableHead>
    <TableRow>
      <TableCell padding="checkbox">
        <Checkbox
          color="primary"
          indeterminate={numSelected > 0 && numSelected < rowCount}
          checked={rowCount > 0 && numSelected === rowCount}
          onChange={onSelectAll}
        />
      </TableCell>
      {headCells.map(headCell => (
        <TableCell key={headCell.field} sortDirection={orderBy === headCell.field ? order : false}>
          <TableSortLabel
            active={orderBy === headCell.field}
            direction={orderBy === headCell.field ? order : 'asc'}
            onClick={() => onSort(headCell.field)}
          >
            {headCell.label}
          </TableSortLabel>
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);

interface HeadCell<T> {
  field: keyof T;
  label: string;
}

const headCells: readonly HeadCell<UserDto>[] = [
  {
    field: 'id',
    label: 'Id',
  },
  {
    field: 'username',
    label: 'Nume',
  },
  {
    field: 'email',
    label: 'Email',
  },
  {
    field: 'roles',
    label: 'Roluri',
  },
  {
    field: 'softDeleted',
    label: 'Activ',
  },
];
