import { makeStyles } from '@sgde/core';

export const useStyles = makeStyles()(theme => ({
  dialog: {
    '& .MuiPaper-root': {
      padding: theme.spacing(2),
      width: theme.spacing(75),
    },
  },
}));
