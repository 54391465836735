import { DrawerProps, Drawer as MuiDrawer } from '@mui/material';
import { ReactNode } from 'react';
import { useStyles } from './Drawer.styles.ts';

type DrawerType = Pick<DrawerProps, 'anchor' | 'variant' | 'open' | 'onClose' | 'className'> & { children: ReactNode };

export const Drawer = ({ children, className, ...props }: DrawerType) => {
  const { classes, cx } = useStyles();
  return (
    <>
      {/* Default drawer */}
      <MuiDrawer {...props} className={cx(classes.root, classes.default, className)}>
        {children}
      </MuiDrawer>

      {/* Mobile drawer */}
      <MuiDrawer
        {...props}
        variant="temporary"
        ModalProps={{ keepMounted: true }}
        className={cx(classes.root, classes.mobile, className)}
      >
        {children}
      </MuiDrawer>
    </>
  );
};
