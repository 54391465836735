import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import {
  Login as LoginIcon,
  Logout as LogoutIcon,
  Menu as MenuIcon,
  MenuOpen as MenuOpenIcon,
} from '@mui/icons-material';
import { Box, Divider, IconButton, ListItem, ListItemIcon, Menu, MenuItem } from '@mui/material';
import { MouseEvent, ReactElement, useEffect, useRef, useState } from 'react';
import { loginRequest } from '../auth/config.ts';
import { ThemeSwitcher } from './ThemeSwitcher.tsx';

interface IProps {
  children?: ReactElement;
  hideLogin?: boolean;
}

export const ProfileMenu = ({ children, hideLogin }: IProps) => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const menuRef = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleLogin = () => instance.loginRedirect(loginRequest);
  const handleLogout = () => {
    handleClose();
    instance.logout();
  };

  useEffect(() => {
    const handleClick = (event: Event) => {
      const menuItem = (event.target as HTMLElement).closest('[role="menuitem"]');
      if (menuItem) {
        handleClose();
      }
    };

    if (menuRef.current) {
      menuRef.current.addEventListener('click', handleClick);
    }

    return () => {
      menuRef.current?.removeEventListener('click', handleClick);
    };
  }, [menuRef.current]);

  return (
    <Box>
      <IconButton onClick={handleClick}>{open ? <MenuOpenIcon /> : <MenuIcon />}</IconButton>
      <Menu
        keepMounted
        open={open}
        ref={menuRef}
        anchorEl={anchorEl}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <ListItem>
          <ThemeSwitcher />
        </ListItem>
        <Divider />
        {children}
        {!hideLogin && isAuthenticated && (
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        )}
        {!hideLogin && !isAuthenticated && (
          <MenuItem onClick={handleLogin}>
            <ListItemIcon>
              <LoginIcon />
            </ListItemIcon>
            Login
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
};
