import {
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  SendIcon,
  Typography,
  UploadIcon,
  useNavigate,
} from '@sgde/core';
import { MouseEvent, useState } from 'react';
import { RESOURCE_TYPES } from '../../constants/resources.ts';
import { ROUTES } from '../../router/routes.ts';

type ConversationResourcesProps = {
  className: string | undefined;
};
export const ConversationResources = ({ className }: ConversationResourcesProps) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton disableRipple className={className} onClick={handleClick}>
        <UploadIcon />
      </IconButton>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuItem>
          <ListItemIcon>
            <SendIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Fisier</ListItemText>
        </MenuItem>
        <Divider textAlign="center">
          <Typography variant="caption" color="textSecondary">
            In colectia mea
          </Typography>
        </Divider>
        <MenuItem onClick={() => navigate(ROUTES.RESOURCES.path)}>
          <ListItemText>Vezi toate resursele</ListItemText>
        </MenuItem>
        {Object.values(RESOURCE_TYPES).map(option => (
          <MenuItem
            key={option}
            disabled={option === RESOURCE_TYPES.DATABASE}
            onClick={() => navigate(ROUTES.RESOURCES.path, { state: { init: option } })}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
