import {
  Box,
  DataGrid,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowModel,
  GridRowModesModel,
  GridSlots,
  Stack,
} from '@sgde/core';
import { useState } from 'react';
import { RESOURCE_TYPES } from '../../constants/resources.ts';
import { ResourceDto } from '../../models/dto/chat/resource.ts';
import { WebPageDto } from '../../models/dto/chat/webPage.ts';
import { useResources, useUpdateResource } from '../../store/resourceApi.ts';
import {
  useCreateWebPageResource,
  useUpdateWebPageResource,
  useWebPageResources,
} from '../../store/webPageResourceApi.ts';
import { useFileResourcesColumns } from './hooks/useFileResourcesColumns.tsx';
import { useWebPageResourcesColumns } from './hooks/useWebPageResourcesColumns.tsx';
import { useStyles } from './ResourcesTable.styles.ts';
import { Toolbar } from './TableToolbar.tsx';

export const ResourcesTable = () => {
  const { classes } = useStyles();
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 25 });
  const [selectedMenu, setSelectedMenu] = useState(localStorage.getItem('resourceType') || RESOURCE_TYPES.FILE);

  // File resource
  const { data: resources } = useResources(undefined, { skip: selectedMenu !== RESOURCE_TYPES.FILE });
  const [updateResource] = useUpdateResource();
  const { fileColumns } = useFileResourcesColumns({ rowModesModel, setRowModesModel, resources });

  // Web page resource
  const { data: webPageResources } = useWebPageResources(undefined, { skip: selectedMenu !== RESOURCE_TYPES.WEB_PAGE });
  const [createWebPageResource] = useCreateWebPageResource();
  const [updateWebPageResource] = useUpdateWebPageResource();
  const { webPageColumns } = useWebPageResourcesColumns({
    rowModesModel,
    setRowModesModel,
    webPageResources,
  });

  const processWebPageRowUpdate = (newRow: GridRowModel) => {
    if (!newRow.id) {
      createWebPageResource(newRow as WebPageDto);
    } else {
      updateWebPageResource(newRow as WebPageDto);
    }
    return newRow;
  };

  const processFileRowUpdate = (newRow: GridRowModel) => {
    updateResource(newRow as ResourceDto);
    return newRow;
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const columns = selectedMenu === RESOURCE_TYPES.FILE ? fileColumns : webPageColumns;
  const rows = selectedMenu === RESOURCE_TYPES.FILE ? resources : webPageResources;

  return (
    <Stack className={classes.stack}>
      <DataGrid
        className={classes.root}
        editMode="row"
        columns={columns}
        rows={rows ?? []}
        hideFooterSelectedRowCount
        rowModesModel={rowModesModel}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={selectedMenu === RESOURCE_TYPES.FILE ? processFileRowUpdate : processWebPageRowUpdate}
        onRowModesModelChange={setRowModesModel}
        isCellEditable={({ row }) => !!row.state}
        isRowSelectable={({ row }) => !!row.state}
        slots={{
          toolbar: Toolbar as GridSlots['toolbar'],
          noRowsOverlay: NoRowsOverlay,
          noResultsOverlay: NoResultsOverlay,
        }}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        slotProps={{
          toolbar: {
            selectedMenu,
            setSelectedMenu,
            setRowModesModel,
            setPaginationModel,
          },
        }}
        columnVisibilityModel={{ identifierType: false, cronJob: false }}
      />
    </Stack>
  );
};

const NoRowsOverlay = () => (
  <Stack alignItems="center" justifyContent="center" height="100%">
    <Box>Nu există resurse disponibile. Pentru a adăuga noi resurse, apăsați butonul '+'.</Box>
  </Stack>
);

const NoResultsOverlay = () => (
  <Stack alignItems="center" justifyContent="center" height="100%">
    <Box>Nu au fost găsite resurse.</Box>
  </Stack>
);
