import { Chip, Tooltip, useUserRoles } from '@sgde/core';
import { ResourceState as ResourceStateType } from '../../../models/dto/chat/resource.ts';

interface IProps {
  state: keyof typeof ResourceStateType;
}

export const ResourceState = ({ state }: IProps) => {
  const { hasRole } = useUserRoles();

  return (
    <Tooltip title={getStateTooltip(state)}>
      <Chip
        label={getStateLabel(state, hasRole('Admin'))}
        size="small"
        variant="outlined"
        color={getStateColor(state)}
      />
    </Tooltip>
  );
};

const getStateColor = (state: keyof typeof ResourceStateType) => {
  switch (state) {
    case 'InsertFailed':
      return 'error';
    case 'ProcessingFailed':
      return 'error';
    case 'Inserted':
      return 'warning';
    case 'MissingDetails':
      return 'error';
    case 'Processing':
      return 'info';
    case 'Active':
      return 'success';
    case 'Public':
      return 'success';
    default:
      return 'error';
  }
};

const getStateLabel = (state: keyof typeof ResourceStateType, isAdmin?: boolean) => {
  switch (state) {
    case 'InsertFailed':
      return 'Eroare la incarcare';
    case 'ProcessingFailed':
      return 'Erore la procesare';
    case 'Inserted':
      return 'Incarcat';
    case 'MissingDetails':
      return 'Lipsa detalii';
    case 'Processing':
      return 'In procesare';
    case 'Active':
      return 'Activ';
    case 'Public':
      return isAdmin ? 'Public' : ' Activ';
    case undefined:
      return 'Incarcare';
    default:
      return 'Necunoscut';
  }
};

const getStateTooltip = (state: keyof typeof ResourceStateType) => {
  switch (state) {
    case 'MissingDetails':
      return 'Completati titlul, autorul si identificatorul';
    default:
      return '';
  }
};
